.galleryWrap {
  line-height: 0;
  -webkit-column-count: 3; /* split it into 3 columns */
  -webkit-column-gap: 25px; /* give it a (25/2)px gap between columns */
  -moz-column-count: 3;
  -moz-column-gap: 25px;
  column-count: 3;
  column-gap: 25px;
  margin-left: 25px;
  margin-right: 25px;
}

.galleryWrap .single {
  width: 100%;
  cursor: pointer;
}

.galleryWrap .single img {
  width: 100% !important;
  height: auto !important;
  margin-bottom: 25px; /* to match column gap */
}

.galleryWrap .single img:hover {
  transform: scale(1.011);
}

@media (max-width: 1200px) {
  .galleryWrap {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}

@media (max-width: 800px) {
  .galleryWrap {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 400px) {
  .galleryWrap {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}

.slideWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.slideWrap .btnClose,
.slideWrap .btnPrev,
.slideWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;
  z-index: 9999;
}

.btnNext:hover,
.btnPrev:hover,
.btnClose:hover {
  opacity: 1;
}

.slideWrap .btnClose {
  top: 40px;
  right: 40px;
}

.slideWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.slideWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.fullScreenImage {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  -webkit-user-select: none; /*safari*/
  -ms-user-select: none; /*IE*/
  user-select: none;
}
